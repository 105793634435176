// 字体大小
.fontSize {
  .font12 {
    font-size: 12px;
  }
  .font14 {
    font-size: 14px;
  }
  .font15 {
    font-size: 15px;
  }
  .font16 {
    font-size: 16px;
  }
  .font17 {
    font-size: 17px;
  }
  .font18 {
    font-size: 18px;
  }
  .font19 {
    font-size: 19px;
  }
  .font20 {
    font-size: 20px;
  }
  .font21 {
    font-size: 21px;
  }
  .font22 {
    font-size: 22px;
  }
  .font24 {
    font-size: 24px;
  }
  .font26 {
    font-size: 26px;
  }
  .font28 {
    font-size: 28px;
  }
  .font30 {
    font-size: 30px;
  }
  .font33 {
    font-size: 33px;
  }
  .font34 {
    font-size: 34px;
  }
  .font36 {
    font-size: 36px;
  }
  .font38 {
    font-size: 38px;
  }
  .font40 {
    font-size: 40px;
  }
  .font52 {
    font-size: 52px;
  }
  .font56 {
    font-size: 56px;
  }
}

.custom-ant {
  //  进度条
  .ant-progress {
    display: flex;
    align-items: center;

    .ant-progress-outer {
      display: flex;
      align-items: center;
      //width: 196px;
      //height: 11px;

      .ant-progress-inner {
        //background: #2C4672;
        border-radius: 26px;
      }
    }

    .ant-progress-text {
      width: auto;
      margin-left: 10px;
      font-family: 'PingFang SC';
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      //color: #FFFFFF;
    }
  }

  //  carousel样式
  .ant-carousel {
    height: 412px;
  }

  //  select组件样式
  .ant-select {
    width: 100%;
    text-align: center;
    &:not(.ant-select-customize-input) .ant-select-selector {
      font-family: 'PingFang SC';
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      border: none;
      border-radius: 17px;
    }
  }

  //  picker组件样式
  .ant-picker {
    //height: 36px;
    //background: #161C25;
    border: 1px solid #434343;
    border-radius: 17px;

    .ant-picker-input > input {
      font-family: 'DINOffcPro';
      font-size: 16px;
      line-height: 19px;
      //color: #DBDCDD;

      &::placeholder {
        font-family: 'DINOffcPro';
        font-size: 16px;
        line-height: 19px;
        color: #43516C;
      }
    }

    .ant-picker-range-separator, .ant-picker-suffix {
      svg {
        fill: #5C6067;
      }
    }
  }

  //  table组件样式
  .ant-table-wrapper {
    .ant-table-thead {
      > tr > th {
        border-bottom: 1px solid transparent;
      }
      .ant-table-cell {
        //background-color: #1E2531;
        font-family: 'PingFang SC';
        font-weight: bolder;
        &:extend(.fontSize .font18);
        line-height: 18px;
        text-align: center;
        //text-transform: capitalize;
        //color: #DBDCDD;
        &:before {
          display: none;
        }
      }
    }
    .ant-table {
      .ant-table-tbody {
        .ant-table-row {
          .ant-table-cell {
            border: none;
            font-family: 'PingFang SC';
            &:extend(.fontSize .font18);
            line-height: 18px;
            text-align: center;
            //text-transform: capitalize;

            &:before {
              display: none;
            }
          }

          &:hover {
            .ant-table-cell {
              //background-color: #1E2531;
            }
          }
        }

        .ant-table-placeholder {

          .ant-table-cell {
            border: none;
            .ant-empty-description {
              font-family: 'PingFang SC';
              font-weight: 500;
              &:extend(.fontSize .font18);
              line-height: 18px;
              text-transform: capitalize;
              color: #56595E;
            }
          }
        }
      }
    }
  }


  //  pagination组件样式
  .ant-pagination {
    margin-top: 14px;
    justify-content: center;

    .ant-pagination-item {
      border: 1px solid transparent;

      a {
        line-height: inherit;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
        color: #fff;
      }
    }

    .ant-pagination-item-active {
      //background-color: #1E2531;
    }

    .ant-pagination-item-ellipsis {
      color: #1677ff!important;
    }

    .ant-pagination-prev, .ant-pagination-next {
      padding: 0 10px;
      margin: 0 20px 0 10px;
      text-align: center;
      border: 1px solid transparent;

      a {
        font-family: 'PingFang SC';
        font-weight: 500;
        font-size: 22px;
        //color: #FFFFFF;
      }
    }
  }

  //  form组件样式
  .ant-form-item {
    margin-bottom: 28px;

    .ant-form-item-label {
      margin-top: 4px;
      //margin-bottom: 7px;

      label {
        font-family: 'PingFang SC';
        font-weight: 500;
        line-height: 16px;
        &:extend(.fontSize .font18);
      }
    }

    .ant-input-affix-wrapper {
      width: 427px;
      height: 53px;
      padding: 0 0 0 11px;
      border-radius: 12px;

      input {
        padding-left: 34px;
        background-color: transparent;
        //color: #5E636B;
        font-family: 'PingFang SC';
        font-weight: 500;
        font-size: 16px;

        &::placeholder {
          color: #5E636B;
        }
      }
    }

    .ant-form-item-explain {
      color: #adb6c4!important;
      font-family: Montserrat!important;
      font-size: 12px!important;
      font-weight: 500!important;
      text-align: right!important;

      .ant-form-item-explain-error {
        color: #a61d24;
      }
    }
  }

  //  checkbox组件样式
  .ant-checkbox {
    .ant-checkbox-inner {
      width: 18px;
      height: 18px;
      border: 1px solid #434343;
      border-collapse: initial;
      border-radius: 2px;
      transition: all .3s;
      background-color: transparent;
    }
    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: #1a55ef!important;
        border-color: #707070!important;
      }
    }
  }

  //  drawer 组件样式
  &.ant-drawer {
    .ant-drawer-content-wrapper {
      width: 434px !important;
      .ant-drawer-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 82px 0 0;
        overflow-x: hidden;
      }
    }
  }

  //  message的分页组件样式
  &.message-pagination {
    .ant-pagination {
      display: flex;
      justify-content: center;
      margin-top: 30px;

      .ant-pagination-item {
        width: 30px;
        height: 30px;
        a {
          line-height: 30px;
        }
      }

      .ant-pagination-item-active {
        //color: #2C7EDC;
        border: 1px solid transparent;
      }

      .ant-pagination-prev, .ant-pagination-next {
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border: 1px solid #303030;

        svg {
          color: #808389;
        }
      }

      .ant-pagination-item-ellipsis {
        line-height: 30px;
        color: #fff;
      }
    }
  }

  //  外部客服分页样式
  .service-page {
    &.ant-pagination {
      .ant-pagination-prev, .ant-pagination-next, .ant-pagination-item {
        border-radius: 0;
      }
      .ant-pagination-prev, .ant-pagination-next {
        width: auto;
        font-family: PingFang SC;
        font-style: normal;
      }
    }
  }

  //  Slider  组件样式
  .ant-slider {
    width: 452px;
    height: 4px;
    border-radius: 41px;

    .ant-slider-rail {
      //background: #383C4E;
    }

    .ant-slider-track {
      background-color: #153450;
    }

    .ant-slider-handle {
      width: 11px;
      height: 11px;
      box-shadow: none;
      border-radius: 50%;
      background: #4775F6;
    }
  }
}

//  公告的modal样式
.custom-announcement-modal {
  &.announcementModalText {
    .ant-modal {
      width: 705px!important;
      //height: 656px;
      //background: #050736;
      border-radius: 24px;
      border: 3px solid #64B1FF;

      .ant-modal-content {
        padding: 0 0 28px 0;
        background: transparent;
      }
    }
  }
  &.announcementModalPic {
    .ant-modal {
      width: 765px!important;
      padding: 0;

      .ant-modal-content {
        padding: 0;
        //background-color: transparent;
        box-shadow: none;
      }
    }
  }
}

//  投注任务, 充值活动progress样式
.daily-task-progress, .extra-bonus-progress {
  .ant-progress {
    .ant-progress-outer {
      .ant-progress-inner {
        background: #151B25;
        border-radius: 28px;
      }
    }
    .ant-progress-text {
      font-weight: 600;
      font-size: 17px;
      line-height: 17px;
      color: rgba(255, 255, 255, 0.8);
    }
  }
}

//  注册登录的modal样式
.loginRegisterModal {
  .ant-modal {
    width: 938px !important;
    // height: 773px;
    padding: 10px;
    border-radius: 16px;
    .ant-modal-content {
      padding: 0;
      background-color: transparent;
      box-shadow: none;
    }
    .custom-ant .ant-form-item .phone-input{
      width: 100% !important;
      margin-left: auto;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border:1px solid transparent;
      input {
        padding: 0;
      }
      &:hover {
        border: 1px solid #1677ff;
      }
    }
    .custom-ant .ant-form-item .phone-code{
      width: 350px !important;
      border:1px solid transparent;
      &:hover {
        border: 1px solid #1677ff;
      }
    }
    .custom-ant .ant-form-item .phone-select{
      .ant-select-selector{
        height: 53px;
        border-radius: 0;
        .ant-select-selection-item {
          line-height: 53px;
        }
      }
    }
    &.reg-login {
      width: 100% !important;
    }
  }
}

.regLoginSelect {
  &.ant-select-dropdown {
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 48%), 0 6px 16px 0 rgb(0 0 0 / 32%), 0 9px 28px 8px rgb(0 0 0 / 20%);
    .ant-select-item {
      border-radius: 12px;
    }
    .ant-select-item-option-active:not(.ant-select-item-option-disabled),
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: rgb(26, 85, 239) !important;
      color: #FFF!important;
    }
  }
  //  充值组件Select下拉内容样式
  &.reg-select-dropdown {
    width: 150px !important;
    .ant-select-item {
      display: flex;
      align-items: center;
      height: 44px;
      margin-bottom: 5px;
      &:hover, &.ant-select-item-option-selected {
        background-color: rgb(26, 85, 239) !important;
        .optionContent {
          span {
            color: #ffffff;
          }
        }
      }
    }
  }
}



//  用户中心progress组件样式
.personal-center-progress {
  .ant-progress {
    .ant-progress-outer {
      width: 244px!important;
      height: 12px!important;
      .ant-progress-inner {
        //background: #2b313d;
        .ant-progress-bg {
          height: 12px!important;
        }
      }
    }
    .ant-progress-text {
      width: auto;
      margin-left: 9px;
      &:extend(.fontSize .font18);
      line-height: 18px;
      text-transform: capitalize;
      //color: #DDDEDF;
    }
  }
}

//  拼夕夕活动progress
.withdraw-amount-progress {
  .ant-progress-bg {
    background: linear-gradient(90deg, #FD862F 0%, #FDC72F 100%);
  }
  .ant-progress-text {
    color: #fff !important;
  }
}

//  个人头像修改modal组件样式
.modifyAvatarModal {
  .ant-modal {
    width: 590px!important;
    height: 784px;
    //background: #181E29;
    border-radius: 16px;

    .ant-modal-content {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 33px 0 0;
      background: transparent;
    }
  }
}

//  个人信息修改modal组件样式
.personalDataModal {
  .ant-modal {
    width: 479px!important;
    //height: 637px;
    //padding-bottom: 35px!important;
    //background: #0F131A;
    border-radius: 16px;
    overflow: auto;

    .ant-modal-content {
      position: relative;
      padding: 68px 0 35px 0;
      background: transparent;
    }
  }

  //  表单样式
  .personal-data-form {
    .ant-form-item {
      display: flex;
      justify-content: center;
      margin-bottom: 36px;

      .ant-form-item-label {
        margin-bottom: 2px;

        label {
          font-family: 'PingFang SC';
          font-weight: 600;
          //font-size: 18px;
          //line-height: 18px;
          &:extend(.fontSize .font20);
          line-height: 20px;
          //color: #FFFFFF;
        }
      }

      .ant-input-affix-wrapper {
        display: flex;
        align-items: center;
        width: 379px;
        height: 36px;
        padding: 0 0 0 29px;
        //background: #262B34;
        border-radius: 8px;
        border: none;

        .ant-input-prefix {
          margin-inline-end: 0;
          font-family: 'PingFang SC';
          font-weight: 500;
          //font-size: 14px;
          //line-height: 14px;
          &:extend(.fontSize .font16);
          line-height: 16px;
          //color: rgba(255, 255, 255, 0.9);
        }

        input {
          padding-left: 34px;
          //color: #fff;
          font-family: 'PingFang SC';
          font-weight: 500;
          //font-size: 16px;
          &:extend(.fontSize .font18);
          border: none;

          &::placeholder {
            color: #5E636B;
          }
        }
      }

      .ant-input {
        width: 379px;
        height: 36px;
        padding-left: 29px;
        //background: #262B34;
        border-radius: 8px;
        border: none;
        //color: #FFF;

        &:focus {
          color: #93acd3;
        }

        &::placeholder {
          font-family: 'PingFang SC';
          font-weight: 400;
          //font-size: 14px;
          //line-height: 14px;
          &:extend(.fontSize .font16);
          line-height: 16px;
          //color: rgba(255, 255, 255, 0.3);
        }
      }

      .ant-form-item-explain {
        color: #adb6c4!important;
        font-family: Montserrat!important;
        //font-size: 10px!important;
        font-size: 12px !important;
        font-weight: 500!important;
        text-align: right!important;

        .ant-form-item-explain-error {
          color: #a61d24;
        }
      }
    }
  }
}

//  recommend/details模块的select样式
.recommend-details-select {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 36px;
  margin-top: 44px;
  .ant-select {
    &:not(.ant-select-customize-input) .ant-select-selector {
      //background-color: #2C3545;
      font-weight: 400;
      &:extend(.fontSize .font18);
      color: rgba(255, 255, 255, 0.8);
      border: none;
      text-align: center;
    }
  }
}

//  Select下拉内容样式
.filterSelect {
  &.ant-select-dropdown {
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 48%), 0 6px 16px 0 rgb(0 0 0 / 32%), 0 9px 28px 8px rgb(0 0 0 / 20%);
    .ant-select-item {
      border-radius: 12px;
      //&.ant-select-item-option-selected {
      //  background-color: rgb(26, 85, 239) !important;
      //  //border-radius: 12px;
      //  color: #FFF!important;
      //}
      //&.ant-select-item-option-active {
      //  background-color: rgb(26, 85, 239) !important;
      //  border-radius: 12px;
      //  color: #FFF!important;
      //}
    }
    .ant-select-item-option-active:not(.ant-select-item-option-disabled),
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: rgb(26, 85, 239) !important;
      color: #FFF!important;
    }

  }

  //  充值组件Select下拉内容样式
  &.recharge-select-dropdown {
    width: 478px;
    .ant-select-item {
      display: flex;
      align-items: center;
      height: 44px;
      margin-bottom: 5px;
      &:hover, &.ant-select-item-option-selected {
        background-color: rgb(26, 85, 239) !important;
        .optionContent {
          span {
            color: #ffffff;
          }
        }
      }
      .optionContent {
        display: flex;
        align-items: center;

        .payLogo {
          width: 40px;
          height: 40px;
          margin-right: 9px;
        }
      }
    }
  }
}

//   recommend/details模块的table组件样式
.recommend-details-table {
  .ant-table-wrapper {
    .ant-table {
      background-color: transparent;
      border: none;
      border-radius: 8px;
      .ant-table-thead {
        .ant-table-cell {
          background-color: transparent!important;
          &:extend(.fontSize .font18);
          line-height: 18px;
          border: none;
        }
      }

      .ant-table-tbody {
        //border: 1px solid #303030;
        //border-radius: 8px;

        .ant-table-row {
          .ant-table-cell {
            font-weight: 500;
            background-color: transparent!important;
          }
          &:hover {
            .ant-table-cell {
              background-color: transparent!important;
            }
          }
        }

        .ant-table-placeholder {
          background-color: transparent;
          &:hover {
            .ant-table-cell {
              background-color: transparent;
            }
          }
        }
      }
    }
  }

}

//  gameHistory, fundingHistory模块的table组件样式
.game-history-table, .funding-history-table {
  .filters {
    .gameSelect {
      width: 140px;
      //height: 36px;
      margin-left: 34px;
      border: 1px solid #434343;
      border-radius: 17px;
    }
  }
  .ant-table-wrapper {
    .ant-table {
      min-width: 1158px;
      //border: 1px solid #303030;
      border-radius: 8px;
      .ant-table-thead {
        .ant-table-cell {
          &::before {
            display: block;
          }
        }
      }
      .ant-table-tbody {

        .ant-table-row {
          .ant-table-cell {
            font-weight: 500;
          }
          &:hover {
            .ant-table-cell {
              background-color: transparent;
            }
          }
        }

        .ant-table-placeholder {
          border-radius: 8px;
          background-color: transparent;
          &:hover {
            .ant-table-cell {
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}

//  gameHistory模块的select样式
.game-history-table {
  .ant-select {
    &:not(.ant-select-customize-input) .ant-select-selector {
      &:extend(.fontSize .font18);
      line-height: 18px;
      border-radius: 17px;
      .ant-select-selection-placeholder {
        color: #3D4A63;
      }
    }
  }
}

//  fundingHistory模块的select样式
.funding-history-table {
  .ant-table {
    .ant-table-tbody {
      .ant-table-placeholder {
        height: 222px;
      }
    }
  }
}

//  specialTask模块select组件样式
.special-task-select {
  .ant-select {
    &:not(.ant-select-customize-input) .ant-select-selector {
      background-color: initial;
      color: hsla(0,0%,100%,.8);
    }
  }
}

//   specialTask模块progress样式
.special-task-progress {
  .ant-progress {

    .ant-progress-outer {
      width: 182px;
      height: 9px;

      .ant-progress-inner {
        background: #151B25;
        border-radius: 28px;
      }
    }

    .ant-progress-text {
      font-weight: 600;
      &:extend(.fontSize .font19);
      line-height: 19px;
      color: rgba(255, 255, 255, 0.8);
    }
  }
}


//  vip模块Progress组件样式
.vip-progress {
  width: 100%;
  .ant-progress {
    margin-bottom: 44px;

    .ant-progress-outer {
      width: 283px;
      height: 12px;

      .ant-progress-inner {
        //background: #282E36;
      }
    }

    .ant-progress-text {
      &:extend(.fontSize .font16);
      line-height: 16px;
      //color: #FFFFFF;
    }
  }
}

//  奖金列表modal组件样式
.cash-back-modal {
  .ant-modal {
    width: 593px!important;
    min-height: 298px;
    //background: #181E29;
    border-radius: 16px;

    .ant-modal-content {
      position: relative;
      padding: 44px 0 26px;
      //background: #181E29;
      box-shadow: none;
    }
  }
}

//  奖金列表Pagination组件样式
.cash-back-pagination {
  .ant-pagination {
    display: flex;
    justify-content: flex-end;
    margin-right: 52px;
    margin-top: 16px;

    .ant-pagination-item, .ant-pagination-prev, .ant-pagination-next {
      width: 40px;
      height: 41px;
      line-height: 41px;

      &.ant-pagination-prev, &.ant-pagination-next {
        border: none;
      }
      a {
        //width: 40px;
        //height: 41px;
        line-height: 41px;
      }
      //&:hover {
      //  color: #2C7EDC;
      //  border: 1px solid #2C7EDC;
      //}
    }

    //.ant-pagination-item-active {
    //  color: #2C7EDC;
    //  border: 1px solid #2C7EDC;
    //}

    .ant-pagination-prev, .ant-pagination-next {
      //width: 40px;
      svg {
        color: #808389;
      }
    }

    .ant-pagination-item-ellipsis {
      line-height: 41px;
      color: #fff;
    }
  }
}

//  wallet的modal样式
.wallet-modal {
  .ant-modal {
    //width: 526px!important;
    //min-height: 569px;
    //max-height: 700px;
    //overflow: auto;
    width: 526px!important;
    border-radius: 16px;

    .ant-modal-content {
      position: relative;
      width: 526px!important;
      min-height: 569px;
      max-height: 700px;
      overflow: auto;
      padding: 16px 0 0;
      background: transparent;
    }
  }
}

//  外部客服的modal样式
.contact-modal {
  .ant-modal {
    border-radius: 8px;
    //  40 + 30
    .ant-modal-content {
      height: 480px;
    }
  }
}

//  recharge的Form组件样式
.recharge-form {

  .ant-form-item {
    margin-bottom: 20px;
    .ant-input-number-handler-wrap {
      display: none;
    }
    .ant-input-number-affix-wrapper {
      display: flex;
      width: 491px;
      height: 57px;
      line-height: 57px;
      margin: auto;
      padding: 0 0 0 12px;
      border-radius: 8px;
      border: none;
      background: transparent;

      input {
        padding: 0 40px;
        text-align: right;
        border: none;
        height: 57px;
        line-height: 57px;
      }
    }
  }

  .ant-select {
    &:not(.ant-select-customize-input) .ant-select-selector {
      height: 66px;
      line-height: 66px;
      border-radius: 10px;

      .ant-select-selection-placeholder {
        line-height: 66px;
      }

      .ant-select-selection-item {
        height: 66px;
        line-height: 66px;
        font-family: 'PingFang SC';
        font-weight: 500;
        font-size: 16px;
        text-align: center;
        text-transform: capitalize;

        .optionContent {
          display: flex;
          align-items: center;

          .payLogo {
            width: 40px;
            height: 40px;
            margin-right: 12px;
          }
        }
      }

      &.ant-select-open {
        .ant-select-selection-item {
          color: rgba(255, 255, 255, .45)!important;
        }
      }

      .ant-select-arrow {
        color: #FFF;
      }
    }
  }
}

//  充值的Form样式
.withdraw-form {

  .ant-form-item {

    .ant-form-item-label {
      margin-bottom: 8px;
    }

    .ant-input {
      width: 479px;
      height: 55px;
      border-radius: 8px;
      border: none;
      &:focus {
        // color: #93acd3;
      }
    }

    .ant-input-affix-wrapper {
      display: flex;
      width: 479px;
      height: 57px;
      padding: 0 0 0 12px;
      border-radius: 8px;
      border: none;

      input {
        padding: 0 40px;
        text-align: right;
        border: none;

        &::placeholder {
          color: #5E636B;
          opacity: 0.4;
        }
      }
    }
  }
}

//  bonusCode的modal组件样式
.bonus-code-modal {
  .ant-modal {
    width: 526px!important;
    height: 524px;
    border-radius: 16px;

    .ant-modal-content {
      padding: 0;
      background: transparent !important;
      box-shadow: none;
    }
  }
}

//  bonusCode的Form组件样式
.bonus-code-form {

  .ant-form-item {
    padding-left: 23px;

    .ant-form-item-label {
      margin-bottom: 4px;

      label {
        font-weight: 600;
        color: #FFFFFF;
      }
    }

    .ant-input {
      width: 479px;
      height: 55px;
      //background: #0F131A;
      border-radius: 8px;
      border: none;
      //color: #FFF;

      &:focus {
        color: #93acd3;
      }

      &::placeholder {
        opacity: 0.5;
      }
    }
  }
}

.swiper {
  height: 100%;
  //  首页优惠活动swiper
  .swiper-slide {
    overflow: hidden;
  }
  &.banner-swiper {
    .swiper-slide {
      height: 178px;
      border-radius: 12px;

      img {
        width: 100%;
        object-fit: cover;
        height: auto;
      }
    }
  }

  &.gradientDark,
  &.gradientLight {
    .swiper-slide {
      height: 240px;

      img {
        object-fit: cover;
        height: 240px !important;
        width: 100% !important;
      }
    }
  }

  //  24小时奖池swiper样式
  &.hours-swiper {
    .swiper-slide {
      position: relative;
      width: 152px !important;
      height: 130px;
      border-radius: 8px;
      cursor: pointer;
    }
  }

  //  recharge的Swiper样式
  &.recharge-swiper {
    margin: unset!important;
  }

  //  vip界面swiper组件样式
  &.vip-swiper {
    &.swiper {
      height: 660px;
      padding-top: 28px;

      .swiper-wrapper {
        height: auto;

        .swiper-slide {
          width: 370px;
          height: 568px;
          border-radius: 10px;
        }
      }
    }
  }

  //  充值的swiper组件样式
  &.withdraw-swiper {
    .swiper-slide {
      width: 343px!important;
      height: 143px;
      cursor: pointer;
    }
  }

  //  活动中心swiper组件样式
  &.promo-swiper {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    .swiper-wrapper {
      display: flex;
      align-items: center;
      .swiper-slide {
        width: calc(1160px / 4) !important;
        height: 44px;
        border-radius: 8px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        &.active span{
          color: #fff;
        }
        &.swiper-slide-active {
          background: transparent;
        }
        &:first-child {
          margin-left: 5px;
        }
        &:last-child {
          margin-right: 5px;
        }
        span {
          display: inline-block;
          width: 100%;
          text-align: center;
          font-family: PingFang SC;
          font-size: 16px;
          font-weight: 500;
          line-height: 44px;
          text-align: center;
        }
      }
    }
  }

  //  新的首页上部分左侧的banner
  &.home-top-swiper {
    .swiper-slide {
      border-radius: 14px;
      cursor: pointer;
    }
    img {
      transition: all 0.2s;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  &.home-down-left-swiper {
    .swiper-slide {
      height: 140px;
    }
  }
  &.home-top-left-swiper {
    .swiper-slide {
      height: 251px;
    }
  }

  //  新的首页上部分右侧的banner
  &.home-game-right-top-swiper {
    height: 355px;
    width: 100%;
    .swiper-slide {
      flex-shrink: 0;
      position: relative;
      transition-property: -webkit-transform;
      transition-property: transform;
      transition-property: transform,-webkit-transform;
      width: 100%;
      height: 64px;
      border-radius: 8px;
      background: #1d2431;
    }
    img {
      transition: all 0.2s;
      &:hover {
        transform: scale(1.1);
      }
    }
  }

  //  新首页gameList
  &.game-list-swiper {
    img {
      transition: all 0.2s;
    }
  }

  //  新vip页面
  &.new-vip-swiper {
    .swiper-slide {
      width: 1000px !important;
    }
  }
}

//  忘记密码窗口
.forget-password {
  .ant-modal {
    width: 550px !important;
    border-radius: 16px;
    .ant-modal-content {
      padding: 0;
      background-color: transparent;
      box-shadow: none;
    }
  }
}

//  修改密码
.change-password {
  .ant-modal {
    width: 600px !important;
    border-radius: 16px;
    margin: 0 auto;
    .ant-modal-content {
      padding: 0;
      background-color: transparent;
      box-shadow: none;
    }
    .ant-row {
      display: block;
    }
    .custom-ant .ant-form-item {
      margin: 40px;
    }
  }
}

.gradientDark {
  .custom-ant {
    .ant-pagination-prev, .ant-pagination-next,
    .ant-pagination-item {
      background-color: #151515;
      a {
        color: #fff;
      }
    }
  }
}

//  home页搜索
.filter-search {
  width: 250px;
  .ant-input {
    padding: 0 0 0 10px;
  }
}

//  博彩执照
.custom-license-modal {
  .ant-modal-content {
    width: 386px;
    background: transparent;
    padding: 0;
    display: flex;
    justify-content: center;
  }
}

//  活动中心进度条
.promo-progress {
  margin: 16px 0;
  .ant-progress {
    .ant-progress-bg {
      background: linear-gradient(90deg, #FD862F 0%, #FDC72F 100%);
    }
  }
}

//  活动中心，规则描述
.promo-rules-wrap {
  .ant-modal {
    border: 1px solid rgba(255, 255, 255, 0.20);
    border-radius: 16px;
  }
}

//  活动中心，签到活动
.promo-check-wrap {
  .ant-modal {
    width: 526px!important;
  }
  .ant-modal-content {
    min-height: 29.63542rem;
    max-height: 36.45833rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

//  gcash支付modal样式
.online-pay-msg-modal {
  .ant-modal {
    width: 526px !important;
    .ant-modal-content {
      width: 526px;
      height: 414px;
      background: #fff !important;
      border-radius: 16px !important;
    }
  }
}

.promo-review-pagination {
  margin-top: 30px;
  .ant-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-pagination-prev, .ant-pagination-next {
      height: 41px;
      line-height: 41px;
    }
    .ant-pagination-item {
      height: 41px;
      line-height: 41px;
      a {
        line-height: 41px;
      }
    }
  }
}

.vip-swiper {
  .swiper-slide {
    overflow: visible !important;
  }
}

//  返水页面折叠面板样式修改
.about-cash-back-problem {
  .ant-collapse>.ant-collapse-item >.ant-collapse-header {
    color: #FFF;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 18.86px;
  }
  .ant-collapse {
    background-color: transparent;
    border: none;
    .ant-collapse-item {
      border-color: #1D2431;
    }
    .ant-collapse-content {
      color: #758097;
      font-family: Montserrat-SemiBold,Montserrat;
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
      background: transparent;
      border: none;
    }
  }
}

// phlvip模版vip页modal样式
.msgDialogWrap {
  .ant-modal {
    width: 650px !important;
    .ant-modal-content {
      background: #151c26 !important;
      height: auto;
      padding: 20px;
      position: relative;
      border-radius: 0px;
    }
  }
}


//  拼夕夕活动的table样式
.pdd-history-record {
  .ant-table-wrapper {
    .ant-table, .ant-table-thead >tr>th,
    .ant-table .ant-table-tbody .ant-table-row:hover .ant-table-cell,
    .ant-table-placeholder, .ant-table-placeholder:hover >td{
      background: transparent !important;
    }
    .ant-table-thead >tr>th {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        right: 0;
        width: 1px;
        height: 30px;
        background: #606E8A;
      }
      &:last-child:after {
        background: transparent;
      }
    }
    .ant-table {
      cursor: pointer;
      border: none !important;
    }
    .ant-table-placeholder {
      //min-height: 380px;
      border: none !important;
      .ant-table-cell {
        .ant-empty-image svg {
          g {
            ellipse {
              fill: transparent !important;
            }
            g {
              stroke: #939bb0 !important;
              path:last-of-type {
                fill: #313131 !important;
              }
            }
          }
        }
        .ant-empty-description {
          color: #939bb0 !important;
        }
      }
    }
  }
}

.ant-modal {
  .titleBox {
    height: 30px;
    .closeIcon {
      position: absolute;
      right: 20px;
      top: 26px;
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }
}

//  拼夕夕邀请用户链接，二维码
.pdd-invite-modal {
  .ant-modal {
    width: 457px !important;
    border-radius: 20px;
  }
}

//  拼夕夕邀请用户窗口
.pdd-user-invite-modal {
  .ant-modal {
    width: 550px !important;
  }
}
//  拼多多-主页面的swiper
//.pddReportSwiperBox {
//  height: 100%;
//}

//  拼夕夕活动-邀请窗口reward-records样式
.pdd-reward-record-swiper {
  //height: 90px;
  .swiper-slide {
    height: 30px;
    flex-shrink: 0;
    position: relative;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform,-webkit-transform;
    width: 100%;
  }
}

//  拼多多，抽奖结果页面
.pdd-turntable-result-modal {
  .ant-modal {
    width: 100vw !important;
    .ant-modal-content {
      box-shadow: none;
      width: 100%;
      height: 100vh;
    }
  }
  .titleBox {
    .closeIcon{
      width: 20px;
      height: 20px;
      top: 20px;
      right: 0;
      svg path {
        fill: #fff !important;
      }
    }
  }
}

//  拼多多活动窗口页面
.pdd-activity-modal {
  .ant-modal {
    width: 548px !important;
    .ant-modal-content {
      box-shadow: none;
      padding: 30px 0;
    }
  }
  .titleBox {
    margin-bottom: 20px;
    height: 70px;
    .closeIcon {
      right: 0;
      top: 40px;
      z-index: 2;
      svg path {
        fill: #fff !important;
      }
    }
  }
}

//  拼多多活动页顶部的swiper广播用户提现信息
.pdd-received-record-swiper {
  margin-left: 0;
  .swiper-slide {
    height: 56px;
    flex-shrink: 0;
    position: relative;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform,-webkit-transform;
    width: 100%;
    p span {
      color: #f9eb2d !important;
    }
  }
}

//  pdd活动-在此确认窗口
.pdd-prompt-modal {
  .ant-modal {
    width: 526px !important;
    border-radius: 20px;
  }
  .titleBox {
    text-align: center;
    font-family: Montserrat;
    font-size: 28px;
    font-weight: 700;
    line-height: 59px;
    .closeIcon {
      top: 0;
    }
  }
}

//  下载app，modal
.app-download-modal {
  .ant-modal {
    width: 526px !important;
    border-radius: 16px;
    overflow: hidden;
    .ant-modal-content {
      padding: 0;
    }
  }
}

// 首页游戏列表Progress样式
.games-list-process {
  .ant-progress {
    position: relative;
    .ant-progress-outer {
      width: 100% !important;
      margin-right: 0 !important;
      padding-right: 0 !important;
    }
    .ant-progress-inner {
      background: #D7D7D7 !important;
      border-radius: 10px;

      .ant-progress-bg {
        border-radius: 10px;
        height: 24px !important;
      }
      
    }
    .ant-progress-text {
      position: absolute !important;
      left: 0%;
      color: #fff;
      font-size: 14px;
      line-height: 24px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

//  idr盘口针对侧边栏 遮罩层
.siderbar-idr-modal {
  .ant-modal {
    width: auto !important;
    //height: 656px;
    //background: #050736;
    border-radius: 24px;
    .ant-modal-content {
      background: transparent;
    }
  }
}
// 金币大奖的范爷样式
.big-reward-pagination {
  .ant-pagination {
    display: flex;
    justify-content: flex-end;
    margin-right: 52px;
    margin-top: 16px;

    .ant-pagination-item, .ant-pagination-prev, .ant-pagination-next {
      width: 40px;
      height: 41px;
      line-height: 41px;
      background-color: #C1D0EE;
      color: #3D4A63 !important;

      &.ant-pagination-prev, &.ant-pagination-next {
        border: none;
      }
      a {
        //width: 40px;
        //height: 41px;
        color: #3D4A63 !important;
        line-height: 41px;
      }
      //&:hover {
      //  color: #2C7EDC;
      //  border: 1px solid #2C7EDC;
      //}
    }

    .ant-pagination-item-active {
     color: #2C7EDC;
     border: 1px solid #2C7EDC;
    }

    .ant-pagination-prev, .ant-pagination-next {
      //width: 40px;
      svg {
        color: #808389;
      }
    }

    .ant-pagination-item-ellipsis {
      line-height: 41px;
      color: #3D4A63;
    }
  }
}
